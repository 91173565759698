<template>
    <div class="main">
        <div class="main1">
            <!-- <div class="top">
                <van-image class="t1" round :src="avatar" fit="fill" />
                <div class="t2">
                    <div class="t21">{{ nickName }}</div>
                    <div class="t22">ID: {{ userId }}</div>
                </div>
                <div class="line"></div>
                <div class="t3">微信支付宝充值</div>
            </div> -->
            <div class="inputAccount">
                <input class="a" placeholder="请输入充值账号绑定的手机号">
                <div class="b">切换</div>
            </div>
            <div class="tline"></div>

            <div class="balanceCard">
                <div class="tip">当前余额</div>
                <div class="balance">
                    <div class="logo"></div>
                    <div class="amount">0</div>
                </div>
            </div>

            <div class="switch">
                <div :class="type==2? 'wechat_y':'wechat_n'" @click="type=2; initData()">
                    <div class="logo"></div>
                    <div class="txt">微信支付</div>
                </div>
                <!-- <div class="line"></div>
                <div :class="type==3? 'alipay_y':'alipay_n'" @click="type=3; initData()">
                    <div class="logo"></div>
                    <div class="txt">支付宝支付</div>
                </div> -->
            </div>

            <div style="height: 25px;"></div>

            <div v-for="(item, index) in list" :key="index" class="item" @click="unifiedOrder(item)">
                <div class="left">
                    <div class="logo"></div>
                    <div class="amount">
                        <div class="num">{{ item.amount }}</div>
                        <div class="unit">金币</div>
                        <div class="giving">{{ item.first_amount>0 ? item.first_desc : item.activity_desc }}</div>
                    </div>
                </div>
                <div class="right">￥{{ formatRMB(item.price_rmb) }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import { doRequest } from '@/utils/request'
// import { Toast } from 'vant'

export default {
    data() {
        return {
            type: 2, // 2微信 3支付宝

            list: [],
            account: {},

            nickName: '',
            avatar: '',
            userId: '',
        }
    },
    methods: {
        unifiedOrder(item) {
            let a = 1
            if (a == 1) {
                return
            }
            doRequest('/recharge/createOrder', {
                product_id: item.id,
            }, this.$route.query).then(res => {
                let url = res.pay_url
                var link = document.createElement('a');
                link.href=url;
                link.rel = 'external'
                link.click();
            })

            // this.$route.query['productId'] = item.id
            // this.$router.push({
            //     path: '/justRecharge',
            //     query: this.$route.query,
            // })
        },
        formatRMB(rmb) {
            return (parseFloat(rmb, 0) / 100.0).toFixed(2)
        },
        initData() {
            doRequest('/recharge/getProducts', {
                type: parseInt(this.type, 0),
            }, this.$route.query).then(res => {
                this.list = res.products
            })

            // doRequest('/account/detail', {
            //     currency: 1,
            // }, this.$route.query).then(res => {
            //     this.account = res.detail
            // })

            // doRequest('/user/getInfo', {
            //     target_user_id: this.userId,
            // }, this.$route.query).then(res => {
            //     this.nickName = res.user.alias_nick_name
            //     this.avatar = res.user.alias_avatar
            // })
        }
    },
    mounted() {
        // this.userId = this.$route.query['userId']
        this.initData()
    }
}
</script>

<style scoped>
.main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 375px;
}

.main1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 375px;
}

input::-ms-input-placeholder{
    text-align: left;
    font-size: 15px;
    color: #999999FF;
}
input::-webkit-input-placeholder{
    text-align: left;
    font-size: 15px;
    color: #999999FF;
}

.inputAccount {
    display: flex;
    /* justify-content: center; */
    align-items: center;
    width: 375px;
    height: 71px;
}

.inputAccount .a {
    width: 195px;
    height: 14.5px;
    margin-left: 18.5px;
}

.inputAccount .b {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 74.5px;

    width: 70.5px;
    height: 37px;
    border: 1px solid #999999;
    border-radius: 18.5px;

    font-size: 15px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    text-align: center;
}

.top {
    width: 375px;
    height: 70px;
    display: flex;
    align-items: center;
    background: #FFFFFF;
    border-radius: 10px;
}

.top .t1 {
    width: 47px;
    height: 47px;
    border-radius: 50%;
    background: black;
    margin-left: 10px;
}

.top .t2 {
    height: 47px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 10px;
}

.top .t2 .t21 {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #333333;
}

.top .t2 .t22 {
    font-size: 13px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #333333;
}

.top .line {
    margin-left: 19px;
    width: 1px;
    height: 26px;
    background: #DDDDDD;
}

.top .t3 {
    margin-left: 21px;
    font-size: 15px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #333333;
}

.tline {
    margin-top: 6px;
    margin-bottom: 17px;
    width: 375px;
    height: 1px;
    background: #DDDDDD;
}

.balanceCard {
    width: 351px;
    height: 140px;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    background: url('../../assets/my_recharge_card.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.balanceCard .tip {
    font-size: 15px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
    margin-top: 29px;
}

.balanceCard .balance {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 32px;
}

.balanceCard .balance .logo {
    width: 27px;
    height: 22px;
    background: url('../../assets/beike.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.balanceCard .balance .amount {
    height: 26px;
    font-size: 33px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 26px;
    margin-left: 9px;
}

.item {
    width: 351px;
    height: 60.33px;
    border-bottom: 1px solid #DDDDDD;

    display: flex;
    justify-content: space-between;
    align-items: center;
}

.item .left {
    display: flex;
    align-items: center;
}

.item .left .logo {
    width: 27px;
    height: 22px;
    background: url('../../assets/beike.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.item .left .amount {
    margin-left: 6px;
    display: flex;
    align-items: flex-end;
}

.item .left .amount .num {
    margin-left: 6px;
    height: 13px;
    font-size: 17px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    line-height: 13px;
}

.item .left .amount .unit {
    height: 10px;
    font-size: 10px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    line-height: 10px;
    margin-left: 6px;
}

.item .left .amount .giving {
    height: 10px;
    font-size: 10px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FDC340;
    line-height: 10px;
    margin-left: 6px;
}

.item .right {
    height: 13px;
    font-size: 17px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    line-height: 13px;
}

.switch {
    display: flex;
    align-items: center;
    width: 351px;
    margin-top: 25px;
}

.switch .wechat_y {
    display: flex;
    align-items: center;
}

.switch .wechat_y .logo {
    background: url('../../assets/wechat_y.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 18px;
    height: 18px;
}

.switch .wechat_y .txt {
    height: 14px;
    font-size: 15px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #333333;
    line-height: 14px;
    margin-left: 4px;
}

.switch .wechat_n {
    display: flex;
    align-items: center;
}

.switch .wechat_n .logo {
    background: url('../../assets/wechat_n.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 15px;
    height: 15px;
}

.switch .wechat_n .txt {
    line-height: 15px;
    height: 15px;
    font-size: 15px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    margin-left: 7px;
}

.switch .line {
    width: 1px;
    height: 10px;
    border: 1px solid #E2E2E2;
    margin-left: 12px;
}

.switch .alipay_y {
    display: flex;
    align-items: center;
    margin-left: 12px;
}

.switch .alipay_y .logo {
    background: url('../../assets/alipay_y.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 18px;
    height: 18px;
}

.switch .alipay_y .txt {
    height: 14px;
    font-size: 15px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #333333;
    line-height: 14px;
    margin-left: 4px;
}

.switch .alipay_n {
    display: flex;
    align-items: center;
    margin-left: 12px;
}

.switch .alipay_n .logo {
    background: url('../../assets/alipay_n.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 15px;
    height: 15px;
}

.switch .alipay_n .txt {
    line-height: 15px;
    height: 15px;
    font-size: 15px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    margin-left: 7px;
}
</style>